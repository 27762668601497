// LAYOUT
$mobile-s: width <= 414px
$mobile-s-from: width => 414px
$mobile: width <= 568px
$mobile-from: width >= 568px
$tablet: width <= 768px
$tablet-from: width >= 768px
$desktop: width <= 992px
$desktop-from: width >= 992px
$wide: width <= 1200px
$wide-from: width >= 1200px
$hd: width <= 1680px
$hd-from: width >= 1680px

// COLORS
$fontColor: #000000
$fontColor70: color($fontColor a(70%))
$fontColor50: color($fontColor a(50%))
$colorGray: #A1A1A1
$colorPrimary: #9B00D2
$colorPrimary2: #6426BC
$colorBlue: #1264FE
$colorYellow: #FEAC12
$colorAqua: #00B6DD
$borderColor: #979797



// OPTIONS
$transitionSpeed: .25s
$ease: ease-in-out


// SOCIALS
$twitter: #00aced
$facebook: #3b5998
$googleplus: #dd4b39
$pinterest: #cb2027
$linkedin: #007bb6
$youtube: #bb0000
$vimeo: #1ab7ea
$tumblr: #32506d
$instagram: #bc2a8d
$flickr: #ff0084
$dribbble: #ea4c89
$quora: #a82400
$foursquare: #0072b1
$forrst: #5B9A68
$vk: #45668e
$wordpress: #21759b
$stumbleupon: #EB4823
$yahoo: #7B0099
$blogger: #fb8f3d
$soundcloud: #ff3a00
